import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../auth-context';

export default function LayoutContainer(props: { requireAuth?: boolean }) {
  // Determine user's authentication state
  let { currentUser } = useAuth();
  let location = useLocation();
  let userState;
  if (currentUser === undefined) {
    userState = 'loading';
  } else if (currentUser === null) {
    userState = 'unauthenticated';
  } else {
    userState = 'authenticated';
  }

  // Redirect user that's in the wrong place based on auth status
  if (userState === 'loading') {
    return <div>Loading...</div>; // Loading state
  } else if (userState === 'authenticated' && !props.requireAuth) {
    console.log('User is already logged in, redirecting to teams page');
    return <Navigate to='/account/teams' state={{ from: location }} replace />;
  } else if (userState === 'unauthenticated' && props.requireAuth) {
    console.log('User is not logged in, redirecting to login page');
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to='/login' state={{ from: location }} replace />;
  }
  return <Outlet />;
}
