import { addDoc, collection, deleteDoc, doc, onSnapshot, orderBy, query, Timestamp, where } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { FSFile } from '../domains/firestore-file';
import { FSWatermarkRequest } from '../domains/firestore-watermark-request';
import { db, storage } from '../firebase';

const listenDecks = (teamId: string, onUpdate: (decks: FSFile[]) => void) => {
  const userFilesRef = collection(db, 'teams', teamId, 'files');
  const q = query(userFilesRef, orderBy('createdAt', 'desc'));
  onSnapshot(q, (snap) => {
    const decks = snap.docs.map(doc => {
      return {
        fileId: doc.id,
        ...doc.data(),
      } as FSFile;
    });
    return onUpdate(decks);
  }, (error) => {
    console.error('listenDecks', error);
  });
};

const deleteDeck = async (teamId: string, deck: FSFile) => {
  await deleteDoc(doc(db, 'teams', teamId, 'files', deck.fileId!));
};

const downloadDeck = async (deck: FSFile) => {
  const pathRef = ref(storage, deck.filePath);
  const signedFileUrl = await getDownloadURL(pathRef);

  const xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onload = (event) => {
    const windowUrl = window.URL || window.webkitURL;
    const a = document.createElement('a');
    a.download = deck.fileName;
    a.href = windowUrl.createObjectURL(xhr.response);
    a.click();
  };
  xhr.open('GET', signedFileUrl);
  xhr.send();
};

const requestWatermarkForDeck = async (teamId: string, userId: string, fileId: string, watermarkText: string) => {
  const watermarkRequest: FSWatermarkRequest = {
    teamId,
    userId,
    createdAt: Timestamp.now(),
    fileId,
    watermarkText,
    completedAt: null,
  };
  const teamWatermarkRequestsRef = collection(db, 'teams', teamId, 'watermarkRequests');
  const docRef = await addDoc(teamWatermarkRequestsRef, watermarkRequest);
  return docRef;
};

const listenWatermarkRequests = (teamId: string, onUpdate: (wmrs: { requestId: string }[]) => void) => {
  const wmrRef = collection(db, 'teams', teamId, 'watermarkRequests');
  const q = query(wmrRef, where('completedAt', '==', null));
  onSnapshot(q, (snap) => {
    const wmrs = snap.docs.map(doc => {
      return {
        requestId: doc.id,
        ...doc.data(),
      };
    });
    return onUpdate(wmrs);
  }, (error) => {
    console.error('listenWatermarkRequests', error);
  });
};

const DeckService = {
  listenDecks,
  deleteDeck,
  downloadDeck,
  requestWatermarkForDeck,
  listenWatermarkRequests,
};
export default DeckService;
