import { Col, Container, Navbar, Row } from 'react-bootstrap';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../auth-context';
import { auth } from '../firebase';
import Sidebar from './layout-components/sidebar';

export default function Layout() {
  // Determine user's authentication state
  let { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const title = determineTitle(location, params);

  return <>
    <div className='my-navbar'>
      <Row>
        <Col sm={4} md={3} xl={2}>
          <Navbar.Brand href='/' className='ps-4'><img src='/logo.svg' alt='DeckStamp logo' /></Navbar.Brand>
        </Col>
        <Col><h1>{title}</h1></Col>
        <Col>
          {currentUser &&
            <Link to='/account' className='nav-link'>
              <>
                Hi, {currentUser.displayName}
                {currentUser.photoURL && <img src={currentUser.photoURL} alt='User profile' className='profile-picture' />}
              </>
            </Link>
          }
        </Col>
      </Row>
    </div>

    <Container style={{ maxWidth: 'none' }}>
      <Row>
        <Col sm={4} md={3} xl={2}>
          {renderSidebar(location, params, navigate)}
        </Col>
        <Col>
          <Outlet />
        </Col>
      </Row>
    </Container>

  </>;
}

const determineTitle = (location: any, params: any) => {
  if (params.projectId) {
    // teams/:teamId/projects/:projectId
    // Todo determine project name, and make title `Project: {project name}`
  }

  const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);
  const elements = location.pathname.split('/');
  return capitalize(elements.pop());
};

const renderSidebar = (location: any, params: any, navigate: any) => {
  if (location.pathname.slice(0, 8) === '/account')
    return <Sidebar title='My Account' items={[
      { title: 'Profile', onClick: () => navigate(`/account/profile`) },
      { title: 'Teams', onClick: () => navigate(`/account/teams`) },
      { title: 'Log out', onClick: () => auth.signOut().then(() => navigate('/login')) }
    ]} />;
  else
    return <Sidebar title='<todo team name>' items={[
      // { title: 'Projects', onClick: () => navigate(`/teams/${params.teamId}/projects`) },
      { title: 'Decks', onClick: () => navigate(`/teams/${params.teamId}/decks`) },
      { title: 'Members', onClick: () => navigate(`/teams/${params.teamId}/members`) },
      // { title: 'Billing', onClick: () => navigate(`/teams/${params.teamId}/billing`) },
      // { title: 'Settings', onClick: () => navigate(`/teams/${params.teamId}/settings`) },
    ]} />;
};
