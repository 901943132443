import { addDoc, collection, doc, DocumentData, getDoc, onSnapshot, orderBy, query, Timestamp } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { FSTeam, FSTeamMember, FSTeamPendingInvite } from '../domains/firestore-team';
import { db, functions } from '../firebase';

const flattenTeamMap = (teamMap: any) => teamMap ? Object.keys(teamMap).map(teamId => ({ teamId: teamId, ...teamMap[teamId] })) : [];

const listenUserProfile = (userId: string, onUpdate: (params: { teams: FSTeam[], pendingInvites: any[], userProfile: DocumentData }) => void) => {
  const userProfileRef = doc(db, 'userProfiles', userId);

  onSnapshot(userProfileRef, (snap) => {
    const userProfile = snap.data()!;
    if (userProfile) {
      const teams = flattenTeamMap(userProfile.memberOfTeams).sort((a: any, b: any) => a.teamName - b.teamName);
      const pendingInvites = flattenTeamMap(userProfile.pendingTeamInvites).sort((a: any, b: any) => a.teamName - b.teamName);
      return onUpdate({ teams, pendingInvites, userProfile });
    } else {
      console.log('listenUserProfile userProfile is undefined');
    }
  }, (error) => {
    console.error(error);
  });
};

const getTeam = async (teamId: string): Promise<FSTeam> => {
  const team = await getDoc(doc(db, 'teams', teamId));
  return {
    teamId,
    ...team.data(),
  } as FSTeam;
};

const listenTeamMembers = async (teamId: string, onUpdate: (teamMembers: FSTeamMember[]) => void) => {
  const membersReference = await collection(db, 'teams', teamId, 'members');
  const q = query(membersReference, orderBy('displayName', 'desc'));
  onSnapshot(q, (snap) => {
    const teamMembers = snap.docs.map(doc => {
      return {
        teamMemberId: doc.id,
        ...doc.data(),
      } as FSTeamMember;
    });
    return onUpdate(teamMembers);
  }, (error) => {
    console.error('listenTeamMembers', error);
  });
};

const inviteTeamMember = async (teamId: string, name: string, email: string): Promise<void> => {
  await addDoc(collection(db, 'teams', teamId, 'pendingInvites'), {
    displayName: name,
    email,
    invitedAt: Timestamp.now(),
  });
  return;
};

const listenTeamPendingInvites = async (teamId: string, onUpdate: (teamPendingInvites: FSTeamPendingInvite[]) => void) => {
  const pendingInvitesReference = await collection(db, 'teams', teamId, 'pendingInvites');
  const q = query(pendingInvitesReference, orderBy('displayName', 'desc'));
  onSnapshot(q, (snap) => {
    const teamPendingInvites = snap.docs.map(doc => {
      return {
        teamPendingInviteId: doc.id,
        ...doc.data(),
      } as FSTeamPendingInvite;
    });
    return onUpdate(teamPendingInvites);
  }, (error) => {
    console.error('listenTeamPendingInvites', error);
  });
};

const acceptPendingInvite = async (teamId: string) => {
  const acceptPendingInviteFunction = httpsCallable(functions, 'acceptPendingInvite');
  return acceptPendingInviteFunction({ teamId });
};

const TeamsService = {
  getTeam,
  listenUserProfile,
  listenTeamMembers,
  inviteTeamMember,
  listenTeamPendingInvites,
  acceptPendingInvite,
};
export default TeamsService;
