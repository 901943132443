import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Badge, Button, Form, ListGroup, Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FSTeamMember, FSTeamPendingInvite } from '../domains/firestore-team';
import TeamsService from '../services/teams-service';
import Joi from 'joi';

export default function MembersPage() {
  const [teamMembers, setTeamMembers] = useState<FSTeamMember[]>([]);
  const [teamPendingInvites, setTeamPendingInvites] = useState<FSTeamPendingInvite[]>([]);
  const [inviteName, setInviteName] = useState('');
  const [inviteEmail, setInviteEmail] = useState('');
  const { teamId } = useParams();

  useEffect(() => {
    TeamsService.listenTeamMembers(teamId!, (tms) => {
      setTeamMembers(tms);
    });
    TeamsService.listenTeamPendingInvites(teamId!, (tpi) => {
      setTeamPendingInvites(tpi);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const inviteHandler = async (event: any) => {
    const schema = Joi.object({
      inviteName: Joi.string().min(3).required(),
      inviteEmail: Joi.string().email({ minDomainSegments: 2, tlds: false }).required(),
    });
    if ((schema.validate({ inviteName, inviteEmail })).error)
      return alert('Please enter a valid non-empty name and email to be invited and try again.');

    await TeamsService.inviteTeamMember(teamId!, inviteName, inviteEmail);
    setInviteName('');
    setInviteEmail('');
  };

  return <>
    <Card className='mb-2'>
      <Card.Header>Team Members</Card.Header>
      <ListGroup variant='flush'>
        {teamMembers.map((tm) => (
          <ListGroup.Item key={tm.teamMemberId}>
            {tm.displayName}
            {tm.role === 'owner' && <>{' '}<Badge pill bg='secondary' className='pb-1'>Owner</Badge></>}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Card>
    <Card>
      <Card.Header>Pending Invites</Card.Header>
      <ListGroup variant='flush'>
        {
          teamPendingInvites.length > 0 &&
          teamPendingInvites.map((tpi) =>
            <ListGroup.Item key={tpi.teamPendingInviteId}>
              {tpi.displayName} ({tpi.email}) invited {moment.utc(tpi.invitedAt.toDate()).fromNow()}
            </ListGroup.Item>
          )
        }
        <ListGroup.Item>
          <Form>
            <Stack direction='horizontal' gap={1}>
              <Form.Control type='text' className='me-auto' placeholder='First name' size='sm'
                value={inviteName}
                onChange={e => setInviteName(e.target.value)} />
              <Form.Control type='email' className='me-auto' placeholder='name@example.com' size='sm'
                value={inviteEmail}
                onChange={e => setInviteEmail(e.target.value)} />
              <Button variant='outline-primary' size='sm' onClick={e => inviteHandler(e.target)}>Invite</Button>
            </Stack>
          </Form>
        </ListGroup.Item>
      </ListGroup>
    </Card>
  </>;
}
