import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { AuthProvider } from './auth-context';

import Layout from './layouts/layout';
import AuthLayout from './layouts/auth-layout';
import LayoutContainer from './layouts/layout-container';

import LoginPage from './pages/login';
import SignupPage from './pages/signup';
import DecksPage from './pages/team-decks';
import TeamsPage from './pages/account-teams';
import ProfilePage from './pages/account-profile';
import MembersPage from './pages/team-members';


export default function App() {
  const [myState, setMyState] = React.useState<any>(null);
  const { currentUser } = myState || {};

  useEffect(() => {
    onAuthStateChanged(auth, (user) => setMyState({ currentUser: user }));
  }, []);

  return (
    <AuthProvider value={{ currentUser }}>
      <Routes>
        <Route path='/' element={<Navigate replace to='/login' />} />

        <Route element={<LayoutContainer />}>
          <Route element={<AuthLayout />}>
            <Route path='/login' element={<LoginPage />} />
            <Route path='/signup' element={<SignupPage />} />
          </Route>
        </Route>

        <Route element={<LayoutContainer requireAuth />}>
          <Route element={<Layout />}>
            <Route path='/account' element={<Navigate replace to='/account/profile' />} />
            <Route path='/account/profile' element={<ProfilePage />} />
            <Route path='/account/teams' element={<TeamsPage />} />

            {/* <Route path='/teams/:teamId' element={<Navigate replace to='/teams/:teamId/projects' />} /> */}
            {/* <Route path='/teams/:teamId/projects' element={} /> */}
            {/* <Route path='/teams/:teamId/projects/:projectId' element={} /> */}
            {/* <Route path='/teams/:teamId/members' element={} /> */}
            {/* <Route path='/teams/:teamId/settings' element={} /> */}
            <Route path='/teams/:teamId/decks' element={<DecksPage />} />
            <Route path='/teams/:teamId/members' element={<MembersPage />} />
          </Route>
        </Route>

      </Routes>
    </AuthProvider>
  );
}
