import React, { useEffect, useState } from 'react';
import { Card, Badge, ListGroup, Stack, Button, Spinner } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../auth-context';
import { FSTeam } from '../domains/firestore-team';
import TeamsService from '../services/teams-service';

export default function TeamsPage() {
  const { currentUser } = useAuth();
  const [teams, setTeams] = useState<FSTeam[]>([]);
  const [pendingInvites, setPendingInvites] = useState<FSTeam[]>([]);
  const [loadingStates, setLoadingStates] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    TeamsService.listenUserProfile(currentUser.uid, (params) => {
      setTeams(params.teams);
      setPendingInvites(params.pendingInvites);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAcceptInvite = async (teamId: string) => {
    setLoadingStates([...loadingStates, teamId]);
    await TeamsService.acceptPendingInvite(teamId);
    setLoadingStates(loadingStates.filter(ls => !teamId));
  };

  return <Card>
    <Card.Header>My Teams</Card.Header>
    <ListGroup variant='flush'>
      {teams.map((team) =>
        <ListGroup.Item key={team.teamId} action onClick={() => navigate(`/teams/${team.teamId}/decks`)}>
          {team.teamName}
          {team.role === 'owner' && <>{' '}<Badge pill bg='secondary' className='pb-1'>Owner</Badge></>}
        </ListGroup.Item>
      )}
      {pendingInvites.map((pi) =>
        <ListGroup.Item key={pi.teamId}>
          <Stack direction='horizontal' gap={0}>
            <div className='me-auto text-secondary'>{pi.teamName} [pending invite]</div>
            <Button size='sm' variant='primary' disabled={loadingStates.includes(pi.teamId!)} onClick={() => handleAcceptInvite(pi.teamId!)}>
              {loadingStates.includes(pi.teamId!) ? <Spinner animation='border' as='span' size='sm' role='status' aria-hidden='true' /> : 'Accept invite'}
            </Button>
          </Stack>
        </ListGroup.Item>
      )}
      {teams.length === 0 && <ListGroup.Item>You are in no teams. Create your first team!</ListGroup.Item>}
    </ListGroup>
  </Card>;
}
