import { Nav } from 'react-bootstrap';

interface SidebarItem {
  title: string;
  // icon: any;
  onClick: any;
}

interface SidebarProps {
  title: string,
  items: SidebarItem[],
};

export default function Sidebar(props: SidebarProps) {
  return <div className='ps-4'>
    <h4>{props.title}</h4>
    <Nav className='flex-column'>
      {props.items.map(i => <Nav.Link onClick={i.onClick} key={i.title}>{i.title}</Nav.Link>)}
    </Nav>
  </div>;
}
