import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { useLocation } from "react-router-dom";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { auth } from '../firebase';
import { Card } from 'react-bootstrap';

export default function SignupPage() {
  let location = useLocation() as any;

  let from = location.state?.from?.pathname || '/account/teams';

  const uiConfig = {
    signInFlow: 'popup',
    signInSuccessUrl: from,
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult: any, redirectUrl: any) => {
        // console.log('signInSuccessWithAuthResult', authResult);
        // auth.signin(authResult.user).then(() => navigate(from, { replace: true }));
        return true;
      },
    }
  };

  return <Card style={{ maxWidth: '410px' }}>
    <Card.Header>
      <img src='/logo.svg' alt='DeckStamp logo' />
    </Card.Header>
    <Card.Body>
      <strong>Take back control of your process</strong>
      <p>Sign up now to start watermarking and tracking your pitch decks.</p>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
      <br />
      <p>Already have an account? <strong><a href='/long'>Sign in</a></strong></p>
    </Card.Body>
  </Card>;
}
