import { Outlet } from 'react-router-dom';

export default function AuthLayout() {
  return <div style={{
    display: 'flex',
    alignItems: 'center',
    minHeight: '100vh',
    justifyContent: 'center',
    padding: '0 1rem',
    marginTop: '-5rem',
  }}>
    <Outlet />
  </div>;
}
