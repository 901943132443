import { User } from 'firebase/auth';
import { Card, ListGroup, Stack } from 'react-bootstrap';
import { useAuth } from '../auth-context';

export default function ProfilePage() {
  const { currentUser } = useAuth();
  const user: User = currentUser;

  return <Card>
    <Card.Header>My Profile</Card.Header>
    <ListGroup variant='flush'>
      <ListGroup.Item>
        <Stack direction='horizontal' gap={2} className='mx-auto'>
          <div><strong>Name</strong></div>
          <div>{user.displayName}</div>
        </Stack>
      </ListGroup.Item>
      <ListGroup.Item>
        <Stack direction='horizontal' gap={2}>
          <div><strong>Email</strong></div>
          <div>{user.email}</div>
        </Stack>
      </ListGroup.Item>
      <ListGroup.Item>
        <Stack direction='horizontal' gap={2}>
          <div><strong>Authentication</strong></div>
          <div><em>Signed in with Google</em></div>
        </Stack>
      </ListGroup.Item>
    </ListGroup>
  </Card>;
}
