import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Container, Card, Button, Form, Spinner, Stack, ListGroup } from 'react-bootstrap';
import { useAuth } from '../auth-context';
import { FSFile } from '../domains/firestore-file';
import { FileUploadButton } from '../helpers/file-upload-button';
import DeckService from '../services/deck-service';
import FileService from '../services/file-service';
import { BsCloudDownloadFill, BsFillTrashFill } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import { simplifyName } from '../helpers/simplify-name';
import { FSTeamMember } from '../domains/firestore-team';
import TeamsService from '../services/teams-service';

export default function TeamPage() {
  const { currentUser } = useAuth();
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [watermarkText, setWatermarkText] = useState('');
  const [deckIdToStamp, setDeckIdToStamp] = useState('');
  const [templateDecks, setTemplateDecks] = useState<FSFile[]>([]);
  const [stampedDecks, setStampedDecks] = useState<FSFile[]>([]);
  const [watermarkRequests, setWatermarkRequests] = useState<any[]>([]);
  const [teamMembers, setTeamMembers] = useState<FSTeamMember[]>([]);
  let { teamId } = useParams();

  const handleFileUpload = (event: any) => {
    let currentFile = event.target.files[0];
    setUploadInProgress(true);

    FileService.uploadUserDeck(currentFile, teamId!, currentUser.uid, (uploadProgress) => {
      if (uploadProgress !== 100)
        setUploadInProgress(true);
      else
        setUploadInProgress(false);
    }, () => {
      console.log('Upload completed!');
      setUploadInProgress(false);
    });

    // Reset the input
    event.target.value = null;
  };

  const stampDeck = async (event: any) => {
    if (watermarkText === '')
      return alert('Please set some text to watermark on the deck!');
    setWatermarkText('');
    await DeckService.requestWatermarkForDeck(teamId!, currentUser.uid, deckIdToStamp, watermarkText);
  };

  const deleteDeck = (deck: FSFile) => {
    // eslint-disable-next-line no-restricted-globals
    confirm(`Are you sure?\nThis will permanently delete ${deck.fileName}.`) &&
      DeckService.deleteDeck(teamId!, deck);
  };

  const downloadDeck = (deck: FSFile) => {
    DeckService.downloadDeck(deck);
  };

  useEffect(() => {
    DeckService.listenDecks(teamId!, (decks) => {
      setStampedDecks(decks.filter(d => d.parentFileId));
      setTemplateDecks(decks.filter(d => !d.parentFileId));
      // Update the dropdown selector to point to the latest template deck
      if (deckIdToStamp === '' && decks[0]) {
        const parentDecks = decks.filter(d => !d.parentFileId);
        setDeckIdToStamp(parentDecks[0].fileId!);
      }
      // Filter watermark requests to remove any overlaps with matching completed decks (prevents screen jitters)
      const completedWmrIds = decks.filter(d => d.watermarkRequestId).map(d => d.watermarkRequestId);
      const filteredWmrs = watermarkRequests.filter(wmr => completedWmrIds.indexOf(wmr.watermarkRequestId) === -1);
      setWatermarkRequests(filteredWmrs);
    });
    DeckService.listenWatermarkRequests(teamId!, (requests) => {
      setWatermarkRequests(requests);
    });
    TeamsService.listenTeamMembers(teamId!, (tms) => {
      setTeamMembers(tms);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const DeckListGroupItem = (props: { deck: FSFile }) => {
    return <ListGroup.Item>
      <Stack direction='horizontal' gap={1}>
        <div className='me-auto'>
          {props.deck.fileName.slice(0, -4)}
          {
            teamMembers.length > 0 &&
            <small style={{ color: 'gray', marginLeft: '0.5em'}}>
              {`Created by ${teamMembers.find(tm => tm.teamMemberId === props.deck.creatorUserId)?.displayName}`}
            </small>
          }
        </div>
        <div>{moment.utc(props.deck.createdAt.toDate()).fromNow()}</div>
        <Button variant='light' size='sm' onClick={() => downloadDeck(props.deck)}><BsCloudDownloadFill /></Button>
        <Button variant='light' size='sm' onClick={() => deleteDeck(props.deck)}><BsFillTrashFill /></Button>
      </Stack>
    </ListGroup.Item>;
    ;
  }


  return (
    <Container>
      {templateDecks.length !== 0 &&
        <>
          <Card className='mt-5 bg-primary'>
            <Card.Body>
              <Stack direction='horizontal' gap={3}>
                <Form.Control
                  className='me-auto'
                  type='text'
                  placeholder='Erlich Bachman, Aviato'
                  value={watermarkText}
                  onChange={e => setWatermarkText(e.target.value)}
                />

                <Form.Select onChange={e => setDeckIdToStamp(e.target.value)} className='me-auto'>
                  {templateDecks.map((d) => <option key={d.fileId} value={d.fileId}>{d.fileName}</option>)}
                </Form.Select>

                <Button variant='light' type='submit' onClick={stampDeck} className='me-auto'>
                  Stamp!
                </Button>
              </Stack>
            </Card.Body>
          </Card>

          <Card className='mt-3'>
            <Card.Header className='d-flex justify-content-between align-items-center'>
              <span>Stamped decks</span>
            </Card.Header>
            <ListGroup variant='flush'>
              {watermarkRequests.map(wmr =>
                <ListGroup.Item key={wmr.requestId} style={{ color: 'gray' }}>
                  <Spinner animation='border' variant='secondary' size='sm' />
                  {' '}
                  {simplifyName(templateDecks.find(d => d.fileId === wmr.fileId)!.fileName) + ' - ' + simplifyName(wmr.watermarkText)}
                </ListGroup.Item>
              )}
              {stampedDecks.map((deck) => <DeckListGroupItem deck={deck} key={deck.fileId} />)}
              {stampedDecks.length === 0 && watermarkRequests.length === 0 && <ListGroup.Item>No decks stamped yet.</ListGroup.Item>}
            </ListGroup>
          </Card>
        </>
      }

      <Card className='mt-3 mb-5'>
        <Card.Header className='d-flex justify-content-between align-items-center'>
          <span>Template decks</span>
          <FileUploadButton size='sm' onChange={handleFileUpload} disabled={uploadInProgress} variant='outline-primary'>
            {uploadInProgress ? <Spinner animation='border' as='span' size='sm' role='status' aria-hidden='true' /> : 'Upload'}
          </FileUploadButton>
        </Card.Header>
        <ListGroup variant='flush'>
          {templateDecks.map((deck) => <DeckListGroupItem deck={deck} key={deck.fileId} />)}
          {templateDecks.length === 0 && <ListGroup.Item>Upload your first deck to get started!</ListGroup.Item>}
        </ListGroup>
      </Card>
    </Container>
  );
}
