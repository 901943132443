import React from 'react';
import { Button } from 'react-bootstrap';

export const FileUploadButton = (props: any) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  // const hiddenFileInput = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const _handleButtonClick = () => {
    if (hiddenFileInput.current === null) {
      console.log('hiddenFileInput.current is null');
    } else {
      hiddenFileInput.current.click();
    }
    if (props.onClick)
      props.onClick();
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const _handleSelectInputChange = (event: any) => {
    if (props.onChange)
      props.onChange(event);
  };
  const buttonProps = {
    ...props,
    onChange: undefined,
  };
  return (
    <>
      <Button onClick={_handleButtonClick} {...buttonProps}>
        {props.children}
      </Button>
      <input
        type='file'
        accept='application/pdf'
        ref={hiddenFileInput}
        onChange={_handleSelectInputChange}
        style={{ display: 'none' }}
      />
    </>
  );
};
