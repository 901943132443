import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator, doc, onSnapshot } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyANvsVja7vJW7iyaPnjAucrgaULiQHZ0Oo',
  authDomain: 'deckstamp-production-2817e.firebaseapp.com',
  projectId: 'deckstamp-production-2817e',
  storageBucket: 'deckstamp-production-2817e.appspot.com',
  messagingSenderId: '628739260525',
  appId: '1:628739260525:web:a30d4d4bcebf1043e7bf64',
  measurementId: 'G-CBN9XFGKCX'
};

// Initialize Firebase and Firebase Authentication
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);
getAnalytics(app);

// setLogLevel('debug');

if (process.env.NODE_ENV === 'development') {
  console.log('Development mode. Connecting to emulators.');
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectStorageEmulator(storage, 'localhost', 9199);
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

// Auth claims listener to update token on claims change after creation
auth.onAuthStateChanged((user) => {
  if (user) {
    const docReference = doc(db, 'userProfiles', user.uid);
    onSnapshot(docReference, (doc) => {
      const data = doc.data();
      if (data && data.lastCommitted) {
        user.getIdToken(true);
      }
    });
  }
});

export { auth, db, storage, functions };
