// Tutorial used: https://css-tricks.com/user-registration-authentication-firebase-react/
// import { User } from 'firebase/auth';
import React from 'react';

export const AuthContext = React.createContext<any | null>(null!);

export function useAuth() {
  return React.useContext(AuthContext);
}

export function AuthProvider(params: { children: any, value: any }) {
  const { children, value } = params;
  return (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );
}
